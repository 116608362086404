import { getStrapiMedia } from "./medias";

export function getMetaTags(seo) {
  const tags = [];

  if (seo.metaTitle) {
    tags.push(
      {
        property: "og:title",
        content: seo.metaTitle,
      },
      {
        name: "title",
        content: seo.metaTitle + ' | Forex Motors Dubai',
      },
      {
        name: "twitter:title",
        content: seo.metaTitle,
      }
    );
  }
  if (seo.metaDescription) {
    tags.push(
      {
        name: "description",
        content: seo.metaDescription,
      },
      {
        property: "og:description",
        content: seo.metaDescription,
      },
      {
        name: "twitter:description",
        content: seo.metaDescription,
      }
    );

    tags.push({ name: "twitter:card", content: seo.metaDescription });


  }

  if (seo.metaKeywords) {
    seo.metaKeywords.forEach((element) => element);

    let content = seo.metaKeywords.map((k) => k.content);

    tags.push(
      {
        name: "keywords",
        content: content[0],
      }
    );
  }


  if (seo.shareImage) {
    const imageUrl = getStrapiMedia(seo.shareImage.formats.thumbnail.url);
    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    );
  }
  if (seo.article) {
    tags.push({
      property: "og:type",
      content: "article",
    });
  }
  tags.push({ hid: 'robots', name: 'robots', content: 'index, follow' } )
  tags.push({ hid: 'author', name: 'author', content: 'Forex Motors Dubai' } )
  tags.push({ hid: 'language', name: 'language', content: 'English' } )


  return tags;
}
