//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { getStrapiMedia } from "./../../utils/medias";

export default {
  data: () => ({
    watermarkUrl:
      "https://res.cloudinary.com/forexmotors-com/image/upload/l_Forex-vehicles:watermark/",
  }),
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getStrapiMedia,
  },
};
